
import { componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { i18n } from 'next-i18next';
import { defaultInputProps, labelProps } from './defaultProps';
import { jobsValues } from './options/jobs';

i18n?.init();

// Options
const areeInteresseValues = [
  { label: 'Autoproduzione sostenibile', value: 'AUTOPRODUZIONE SOSTENIBILE' },
  { label: 'Consulenza energetica e ambientale', value: 'CONSULENZA ENERGETICA' },
  { label: 'Economia Circolare', value: 'SERVIZI AMBIENTALI' },
  { label: 'Efficienza Energetica', value: 'RIQUALIFICAZIONE ENERGETICA' },
  { label: 'Green Gas Energy', value: 'Green Gas' },
  { label: 'Mobilità sostenibile', value: "MOBILITA' SOSTENIBILE" },
  { label: 'Smart City e rigenerazione urbana', value: 'SMART CITIES E RIGENERAZIONE URBANA' },
]

// const servizioValues = [
//   { label: 'Cogenerazione e trigenerazione', value: 'Cogenerazione e trigenerazione' },
//   { label: 'Fotovoltaico e batterie', value: 'Fotovoltaico e batterie' },
//   { label: 'Biometano', value: 'Biometano' },
//   { label: 'Idrogeno', value: 'Idrogeno' },
//   { label: 'Utilities energetiche', value: 'Utilities energetiche' },
//   { label: 'Servizi Digitali', value: 'Servizi Digitali' },
//   { label: 'Elettrificazione Flotte', value: 'Elettrificazione Flotte' },
//   { label: 'Mobilità Elettrica', value: 'Mobilità Elettrica' },
//   { label: 'Gestione e valorizzazione dei rifiuti', value: 'Gestione e valorizzazione dei rifiuti' },
//   { label: 'Campionamento e monitoraggio', value: 'Campionamento e monitoraggio' },
//   { label: 'Bonifiche ambientali', value: 'Bonifiche ambientali' },
//   { label: 'Trattamento acque', value: 'Trattamento acque' },
//   { label: 'Altri servizi ambientali', value: 'Altri servizi ambientali' },
//   { label: 'Net zero', value: 'Net zero' },
//   { label: 'Diagnosi e consulenze energetiche', value: 'Diagnosi e consulenze energetiche' },
//   { label: 'Consulenze ambientali', value: 'Consulenze ambientali' },
//   { label: 'Riqualificazione edifici e spazi pubblici', value: 'Riqualificazione edifici e spazi pubblici' },
//   { label: 'Teleriscaldamento', value: 'Teleriscaldamento' },
//   { label: 'Illuminazione pubblica intelligente', value: 'Illuminazione pubblica intelligente' },
//   { label: 'Soluzioni smart per le città', value: 'Soluzioni smart per le città' },
//   { label: 'Servizi digitali per le città', value: 'Servizi digitali per le città' },
//   { label: 'Energy communities', value: 'Energy communities' }
// ]

export const validationMessages = {
  standard: "Formato non corretto",
  required: "Campo richiesto",
  phone: {
    min: "Minimo 8 caratteri",
    max: "Massimo 15 caratteri",
  },
  cap: "Il valore non è un codice postale valido",
  piva: {
    length: "Il campo deve essere di 11 caratteri"
  },
  ipa: {
    max: "Massimo 11 caratteri"
  },
  email: {
    blocklist: "Solamente email aziendali"
  }
}

const companyOrPAOptions = [
  {label: 'Azienda', value: 'Azienda'},
  {label: 'Ente Pubblico', value: 'Ente Pubblico'}
]

/* Global Fields 
--------------------------- */

export const MKTGCampaign = {
  component: componentTypes.TEXT_FIELD,
  name: 'info_campagna__c',
  label: 'Campaign aMKTG',
  placeholder: "",
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  initialValue: '',
  hideField: true
}

export const salesFormAgreement_0 = {
  component: componentTypes.CHECKBOX,
  value: 'Acconsento',
  initialValue: 'Acconsento',
  name: 'consenso_4___autorizzazione_al_ricontatto',
  hideField: true,
}

const provenanceForm = {
  component: componentTypes.TEXT_FIELD,
  name: 'urlfrom__c',
  label: '',
  placeholder: "",
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  hideField: true,
  value: ''
}
export const provenanceForm_company = {
  ...provenanceForm,
  value: "https://edisonnext.it/contattaci/aziende/",
  initialValue: "https://edisonnext.it/contattaci/aziende/",
}
export const provenanceForm_PA = {
  ...provenanceForm,
  value: "https://edisonnext.it/contattaci/pubbliche-amministrazioni/",
  initialValue: "https://edisonnext.it/contattaci/pubbliche-amministrazioni/",
}
export const provenanceForm_sales = {
  ...provenanceForm,
  value: "https://edisonnext.it/form-per-sales/",
  initialValue: "https://edisonnext.it/form-per-sales/",
}

export const generationChannel = {
  component: componentTypes.TEXT_FIELD,
  name: 'canale_di_generazione',
  label: '',
  placeholder: "",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  value: "Web contattaci",
  initialValue: "Web contattaci",
  hideField: true,
}

export const generationChannel_formPerSales = {
  ...generationChannel,
  value: "Attività Commerciale",
  initialValue: "Attività Commerciale",
}

export const generationChannelDetails = {
  component: componentTypes.TEXT_FIELD,
  name: 'dettaglio_canale_di_generazione',
  label: 'Dettaglio Canale di Generazione',
  placeholder: "",
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  hideField: true
}

const companyOrPA = {
  component: componentTypes.SELECT,
  name: 'azienda_o_pubblica_amministrazione_',
  label: 'Azienda o Pubblica Amministrazione?',
  placeholder: "",
  isRequired: true,
  FormFieldGridProps: { xs:12, lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  options: companyOrPAOptions,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }],
  isOptionEqualToValue: (option, value) => option.value === value
};
export const companyOrPA_Company = {
  ...companyOrPA,
  value: 'Azienda',
  initialValue: 'Azienda',
  hideField: true,
};
export const companyOrPA_PA = {
  ...companyOrPA,
  value: 'Ente Pubblico',
  initialValue: 'Ente Pubblico',
  hideField: true,
};

// First step
export const areaInteresse = {
  component: componentTypes.SELECT,
  name: 'tipologia_richiesta__c',
  label: "Area d'interesse principale",
  placeholder: "Scegli l'area di interesse",
  isRequired: true,
  FormFieldGridProps: { xs:12, lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  options: areeInteresseValues,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }],
  isOptionEqualToValue: (option, value) => option.value === value
};

const filteredUlterioriOptions = (value) => {
  const curOptionIndex = areeInteresseValues.findIndex(element => element.value === value);
  const ulterioriAreeInteresseValues = [...areeInteresseValues];
  ulterioriAreeInteresseValues.splice(curOptionIndex, 1);
  
  if(value) {
    return Promise.resolve([...ulterioriAreeInteresseValues]);
  } else {
    return Promise.resolve([]);
  }
};

const filteredOptionsMapper = (value) => {
  return areeInteresseValues.findIndex(element => element.value === value);
};

export const ulterioriAreeInteresse = {
  component: 'enhanced-select',
  name: 'ulteriori_aree_d_interesse__c',
  label: 'ULTERIORI AREE DI INTERESSE (FACOLTATIVO)',
  placeholder: "Scegli ulteriori aree di interesse",
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  ...defaultInputProps,
  isRequired: false,
  isMulti: true,
  simpleValue: true,
  closeMenuOnSelect: false,
  disableCloseOnSelect: true,
  componentsProps: {
    paper: { className: 'contactForm-Paper', }
  },
  isOptionEqualToValue: (option, value) => option.value === value,
  renderOption: (props, option, state) => {
    return (
      <MenuItem {...props}>
        <Checkbox checked={state?.selected} />
        <ListItemText primary={option?.label}/>
      </MenuItem>
    )
  },
  onInputChange: () => null,
  noOptionsMessage: 'Scegli un\'area di interesse',
  resolveProps: (_props, _field, { getState }) => {
    const areaDiInteressePrincipaleValue = getState().values.tipologia_richiesta__c;
    return {
      loadOptionsChangeCounter: filteredOptionsMapper(areaDiInteressePrincipaleValue),
      loadOptions: () => filteredUlterioriOptions(areaDiInteressePrincipaleValue)
    };
  },
};

const filteredServizioOptions = (value) => {

  switch (value) {
    case 'AUTOPRODUZIONE SOSTENIBILE':
      return Promise.resolve([
        {label: 'Cogenerazione e trigenerazione', value: 'Cogenerazione'},
        {label: 'Fotovoltaico e batterie', value: 'FOTOVOLTAICO E STORAGE'}
      ]);
      break;
    
    case 'Green Gas':
      return Promise.resolve([
        {label: 'Idrogeno', value: 'Idrogeno'},
        {label: 'Biometano', value: 'BIOGAS E BIOMETANO'}
      ]);
      break;
      
    case 'RIQUALIFICAZIONE ENERGETICA':
      return Promise.resolve([
        {label: 'Utilities energetiche', value: 'Utilities energetiche'},
        {label: 'Servizi Digitali', value: 'Servizi Digitali'}
      ]);
      break;

    case 'SMART CITIES E RIGENERAZIONE URBANA':
      return Promise.resolve([
        {label: 'Riqualificazione edifici e spazi pubblici', value: 'RIQUALIFICAZIONE SPAZI PUBBLICI'},
        {label: 'Teleriscaldamento', value: 'Teleriscaldamento'},
        {label: 'Illuminazione pubblica intelligente', value: 'ILLUMINAZIONE PUBBLICA'},
        {label: 'Soluzioni smart per le città', value: 'Soluzioni smart per le città'},
        {label: 'Servizi digitali per le città', value: 'Servizi digitali per le città'},
        {label: 'Energy communities', value: 'Energy communities'}
      ]);
      break;

    case "MOBILITA' SOSTENIBILE":
      return Promise.resolve([
        {label: 'Elettrificazione Flotte', value: 'Elettrificazione Flotte'},
        {label: 'Mobilità Elettrica', value: 'Mobilità Elettrica'}
      ]);
      break;

    case 'SERVIZI AMBIENTALI':
      return Promise.resolve([
        {label: 'Gestione e valorizzazione dei rifiuti', value: 'GESTIONE E SMALTIMENTO DEI RIFIUTI INDUSTRIALI'},
        {label: 'Campionamento e monitoraggio', value: 'Campionamento e monitoraggio'},
        {label: 'Bonifiche ambientali', value: 'BONIFICHE AMBIENTALI'},
        {label: 'Trattamento acque', value: 'TRATTAMENTO ACQUE PRIMARIE E REFLUE'},
        {label: 'Altri servizi ambientali', value: 'Altri servizi ambientali'},
      ]);
      break;

    case 'CONSULENZA ENERGETICA':
      return Promise.resolve([
        {label: 'Road To Zero', value: 'Net zero'},
        {label: 'Diagnosi e consulenze energetiche', value: 'Diagnosi e consulenze energetiche'},
        {label: 'Consulenze ambientali', value: 'CONSULENZE IN AMBITO GESTIONE AMBIENTALE'},
      ]);
      break;

    default:
      return Promise.resolve([]);
      break;
  }
};

export const servizio = {
  component: 'enhanced-select',
  name: 'scegli_il_servizio___tendina',
  label: 'SCEGLI IL SERVIZIO',
  placeholder: "Scegli il servizio",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }],
  onInputChange: () => null,
  noOptionsMessage: 'Scegli un\'area di interesse',
  resolveProps: (_props, _field, { getState }) => {
    const areaDiInteressePrincipaleValue = getState().values.tipologia_richiesta__c;
    
    return {
      loadOptionsChangeCounter: filteredOptionsMapper(areaDiInteressePrincipaleValue),
      loadOptions: () => filteredServizioOptions(areaDiInteressePrincipaleValue)
    };
  },
};

export const luogoInteresse = {
  component: componentTypes.SELECT,
  name: 'luogo_di_interesse',
  label: 'LUOGO DI INTERESSE',
  placeholder: "Scegli il luogo dell'intervento",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Abruzzo", value: "Abruzzo" },
    { label: "Basilicata", value: "Basilicata" },
    { label: "Calabria", value: "Calabria" },
    { label: "Campania", value: "Campania" },
    { label: "Emilia-Romagna", value: "Emilia-Romagna" },
    { label: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia" },
    { label: "Lazio", value: "Lazio" },
    { label: "Liguria", value: "Liguria" },
    { label: "Lombardia", value: "Lombardia" },
    { label: "Marche", value: "Marche" },
    { label: "Molise", value: "Molise" },
    { label: "Piemonte", value: "Piemonte" },
    { label: "Puglia", value: "Puglia" },
    { label: "Sardegna", value: "Sardegna" },
    { label: "Sicilia", value: "Sicilia" },
    { label: "Toscana", value: "Toscana" },
    { label: "Trentino-Alto Adige", value: "Trentino-Alto Adige" },
    { label: "Umbria", value: "Umbria" },
    { label: "Valle d'Aosta", value: "Valle d'Aosta" },
    { label: "Veneto", value: "Veneto" },
    { label: "Tutta Italia", value: "Whole of Italy" },
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const motivoContatto = {
  component: componentTypes.TEXT_FIELD,
  name: 'note_richiesta__c',
  label: 'RACCONTACI IL MOTIVO DEL TUO CONTATTO',
  placeholder: "Avrei bisogno di...",
  isRequired: true,
  FormFieldGridProps: { lg: 12 },
  multiline: true,
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

// Second step
export const name = {
  component: componentTypes.TEXT_FIELD,
  name: 'firstname',
  label: 'NOME',
  placeholder: "Inserisci il tuo nome",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[a-zA-Z ']+$/i, message: validationMessages.standard }
  ],
}

export const surname = {
  component: componentTypes.TEXT_FIELD,
  name: 'lastname',
  label: 'COGNOME',
  placeholder: "Inserisci il tuo nome",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[a-zA-Z ']+$/i, message: validationMessages.standard }
  ]
}

export const email = {
  component: componentTypes.TEXT_FIELD,
  name: 'email',
  label: 'EMAIL',
  placeholder: "Inserisci la tua mail: es. nome@xyz.com",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: validationMessages.standard }
  ]
}

export const phone = {
  component: componentTypes.TEXT_FIELD,
  name: 'mobilephone',
  label: 'NUMERO DI TELEFONO',
  placeholder: "Inserisci il tuo numero: es. +39 0123456789",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[\d|\+|\(]+[\)|\d|\s|-]*[\d]$/, message: validationMessages.standard },
    { type: validatorTypes.MIN_LENGTH, threshold: 8, message: validationMessages.phone.min },
    { type: validatorTypes.MAX_LENGTH, threshold: 15, message: validationMessages.phone.max },
  ]
}

export const role = {
  component: componentTypes.SELECT,
  name: 'ruolo',
  label: 'RUOLO',
  placeholder: "Inserisci il tuo ruolo",
  isRequired: true,
  simpleValue: true,
  isReadOnly: false,
  noValueUpdates: true,
  freeSolo: true,
  closeMenuOnSelect: false,
  noOptionsText: 'Nessun risultato',
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps},  },
  inputProps: {...defaultInputProps, readOnly: false },
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Presidente", value: 'Presidente' },
    { label: "Amministratore delegato", value: 'Amministratore delegato' },
    { label: "Direttore generale", value: 'Direttore generale' },
    { label: "Direttore finanziario", value: 'Direttore finanziario' },
    { label: "Dirigente", value: 'Dirigente' },
    { label: "Responsabile di funzione", value: 'Responsabile di funzione' },
    { label: "Responsabile di stabilimento", value: 'Responsabile di stabilimento' },
    { label: "Energy Manager", value: 'Energy Manager' },
    { label: "Altro", value: 'Altro' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const rolePA = {
  component: componentTypes.SELECT,
  name: 'ruolo___pa',
  label: 'RUOLO',
  placeholder: "Inserisci il tuo ruolo",
  isRequired: true,
  simpleValue: true,
  isReadOnly: false,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps},  },
  inputProps: {...defaultInputProps, readOnly: false, },
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Presidente", value: 'Presidente' },
    { label: "Provveditore", value: 'Provveditore' },
    { label: "Direttore generale", value: 'Direttore generale' },
    { label: "Assessore", value: 'Assessore' },
    { label: "Sindaco", value: 'Sindaco' },
    { label: "Dirigente pubblico", value: 'Dirigente pubblico' },
    { label: "Direttore di sito", value: 'Direttore di sito' },
    { label: "Funzionario", value: 'Funzionario' },
    { label: "Responsabile di funzione", value: 'Responsabile di funzione' },
    { label: "Altro", value: 'Altro' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const jobTitle = {
  component: componentTypes.SELECT,
  name: 'job_title___tendina',
  label: 'JOB TITLE (FACOLTATIVO)',
  placeholder: "Inserisci il job title",
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: {  variant: "standard", InputLabelProps: {...labelProps} },
  inputProps: { ...defaultInputProps,  },
  simpleValue: true,
  isClearable: true,
  isSearchable: true,
  noOptionsText: 'Nessun risultato',
  options: jobsValues.filter(job => job.category != 'Pubblico'),
  isOptionEqualToValue: (option, value) => option.value === value,
  isRequired: false,
  // validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const jobTitlePa = {
  component: componentTypes.SELECT,
  name: 'job_title___tendina',
  label: 'JOB TITLE (FACOLTATIVO)',
  placeholder: "Inserisci il job title",
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: {  variant: "standard", InputLabelProps: {...labelProps} },
  inputProps: { ...defaultInputProps,  },
  simpleValue: true,
  isClearable: true,
  isSearchable: true,
  noOptionsText: 'Nessun risultato',
  options: jobsValues.filter(job => job.category != 'Privato'),
  isOptionEqualToValue: (option, value) => option.value === value,
  isRequired: false,
  // validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

// Third step
export const settore = {
  component: componentTypes.SELECT,
  name: 'settore___azienda_solo_per__form_b2b',
  label: 'SETTORE',
  placeholder: "Scegli il settore",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Alberghiero e Ristorazione", value: 'Alberghiero e Ristorazione' },
    { label: "Alimentare", value: 'Alimentare' },
    { label: "Amministrazione pubblica", value: 'Amministrazione pubblica' },
    { label: "Assicurativo", value: 'Assicurativo' },
    { label: "Automotive", value: 'Automotive' },
    { label: "Bancario", value: 'Bancario' },
    { label: "Chimico e Farmaceutico", value: 'Chimico e Farmaceutico' },
    { label: "Commercio", value: 'Commercio' },
    { label: "Edile", value: 'Edile' },
    { label: "Elettronico", value: 'Elettronico' },
    { label: "Energetico", value: 'Energetico' },
    { label: "Fondi e SGR", value: 'Fondi e SGR' },
    { label: "Gomma e Plastica", value: 'Gomma e Plastica' },
    { label: "Istruzione", value: 'Istruzione' },
    { label: "Macchinari", value: 'Macchinari' },
    { label: "Metallurgico", value: 'Metallurgico' },
    { label: "Organizzazioni pubbliche", value: 'Organizzazioni pubbliche' },
    { label: "Sanità", value: 'Sanità' },
    { label: "Stampa e Cartario", value: 'Stampa e Cartario' },
    { label: "Telecomunicazioni", value: 'Telecomunicazioni' },
    { label: "Tessile", value: 'Tessile' },
    { label: "Trasporti e Logistica", value: 'Trasporti e Logistica' },
    { label: "Vetro e Ceramica", value: 'Vetro e Ceramica' },
    { label: "Altro - Industria", value: 'Altro - Industria' },
    { label: "Altro - Terziario", value: 'Altro - Terziario' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const settorePA = {
  component: componentTypes.SELECT,
  name: 'settore___pa_solo_per_form_b2g',
  label: 'SETTORE',
  placeholder: "Scegli il settore",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Amministrazione pubblica", value: 'Amministrazione pubblica' },
    { label: "Istruzione", value: 'Istruzione' },
    { label: "Sanità", value: 'Sanità' },
    { label: "Organizzazioni pubbliche", value: 'Organizzazioni pubbliche' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const societa = {
  component: componentTypes.TEXT_FIELD,
  name: 'company',
  label: 'Company Name',
  placeholder: "Inserisci il nome della società",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const entePubblico = {
  component: componentTypes.TEXT_FIELD,
  name: 'company',
  label: 'Ente Pubblico',
  placeholder: "Inserisci il nome dell'ente pubblico'",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const piva = {
  component: componentTypes.TEXT_FIELD,
  name: 'partita_iva',
  label: 'PARTITA IVA',
  placeholder: "Inserisci la PIVA a 11 cifre",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.EXACT_LENGTH, threshold: 11, message: validationMessages.piva.length}
  ]
}

export const ipa = {
  component: componentTypes.TEXT_FIELD,
  name: 'codice_ipa',
  label: 'Codice IPA (FACOLTATIVO)',
  placeholder: "Inserisci il codice IPA",
  isRequired: false,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.MAX_LENGTH, threshold: 11, message: validationMessages.ipa.max}
  ]
}

export const cap = {
  component: componentTypes.TEXT_FIELD,
  name: 'zip',
  label: 'Postal Code',
  placeholder: "Inserisci il CAP",
  isRequired: false,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.PATTERN, pattern: /^[0-9]{5}([- /]?[0-9]{4})?$/, message: validationMessages.cap },
  ]
}

// privacy
export const marketing1 = {
  component: componentTypes.SWITCH,
  name: 'consenso_1_x_form',
  label: 'Acconsento al trattamento dei miei dati da parte di Edison NEXT per attività promozionali, commerciali e di marketing, come descritto all’art. 4 Par.II lett.A.',
  variant: "standard",
  initialValue: false,
  isRequired: false,
  FormFieldGridProps: { lg: 12 },
  ...defaultInputProps,
}

export const marketing2 = {
  component: componentTypes.SWITCH,
  name: 'consenso_2_x_form',
  label: 'Acconsento alla trasmissione dei miei dati alle Società del Gruppo Edison per proprie attività promozionali, commerciali e di marketing, come descritto all’art. 4 Par. II lett. B.',
  variant: "standard",
  initialValue: false,
  isRequired: false,
  FormFieldGridProps: { lg: 12 },
  ...defaultInputProps,
}

export const marketing3 = {
  component: componentTypes.SWITCH,
  name: 'consenso_3_x_form',
  label: 'Acconsento alla trasmissione dei miei dati a partner commerciali di Edison NEXT per attività promozionali, commerciali e di marketing proprie, come descritto all’art. 4 Par. II lett. C.',
  variant: "standard",
  initialValue: false,
  isRequired: false,
  FormFieldGridProps: { lg: 12 },
  ...defaultInputProps,
}

export const hiddenField = {
  component: componentTypes.CHECKBOX,
  value: true,
  initialValue: true,
  name: 'consenso_4_x_form',
  hideField: true,
}

export const privacy = {
  component: 'plain-text',
  name: 'privacy-policy',
  label: 'Cliccando sul tasto “Invia” confermo di aver preso visione dell’<a href=\"https://email.edisonnext.it/hubfs/Informative%20privacy/A4%20informativa_2022_12_web.pdf\" target=\"_blank\">informativa privacy</a>.',
  gridProps: { lg: 12, mt: 50, mb:16 },
  inputProps: {...defaultInputProps, textAlign: 'center', variant: "description"},
}

// Schemas
export const schemaAziende = [
  // step 1
  {
    fields: [
      companyOrPA_Company,
      areaInteresse,
      ulterioriAreeInteresse,
      servizio,
      luogoInteresse,
      motivoContatto,
    ]
  },
  
  // Step2: Dati del rappresentante
  {
    fields: [
      name,
      surname,
      email,
      phone,
      role,
      jobTitle,
    ]
  },

  // Step3 : Dati della società
  {
    fields: [
      settore,
      societa,
      cap,

      generationChannelDetails,
      MKTGCampaign,

      marketing1,
      marketing2,
      marketing3,
      hiddenField,
      
      generationChannel,
      provenanceForm_company,

      privacy
    ]
  }
]

export const schemaPA = [
  // step 1
  {
    fields: [
      companyOrPA_PA,
      areaInteresse,
      ulterioriAreeInteresse,
      servizio,
      luogoInteresse,
      motivoContatto,
    ]
  },
  
  // Step2: Dati del rappresentante
  {
    fields: [
      name,
      surname,
      email,
      phone,
      rolePA,
      jobTitlePa,
    ]
  },

  // Step3 : Dati della società
  {
    fields: [
      settorePA,
      entePubblico,
      ipa,
      cap,

      generationChannelDetails,
      MKTGCampaign,

      marketing1,
      marketing2,
      marketing3,
      hiddenField,

      generationChannel,
      provenanceForm_PA,

      privacy
    ]
  },
]